// color variants
@import 'themes-vars.module.scss';
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');
// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import 'sportsicon.scss';
@import 'fontawesome.scss';
@import 'games.scss';
@tailwind base;
@tailwind components;
@tailwind utilities;

// preload start
@font-face {
    font-family: 'NanumBarunGothic';
    src: url(../fonts/NanumBarunGothicSubset.woff2) format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: block;
}
body {
    font-family: 'NanumBarunGothic' !important;
    // font-family: 'Nanum Gothic', sans-serif !important;
}
.boxes-bg {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999999999999;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0000004f;
    // background: linear-gradient(to left, rgb(1, 98, 56), rgba(23, 102, 97, 0.5));
}

.boxes {
    opacity: 1;
    height: 32px;
    width: 32px;
    position: relative;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    margin-top: 32px;
    -webkit-transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);
    transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);
}

.boxes .box {
    width: 32px;
    height: 32px;
    top: 0px;
    left: 0;
    position: absolute;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.boxes .box:nth-child(1) {
    -webkit-transform: translate(100%, 0);
    transform: translate(100%, 0);
    -webkit-animation: box1 1s linear infinite;
    animation: box1 1s linear infinite;
}

.boxes .box:nth-child(2) {
    -webkit-transform: translate(0, 100%);
    transform: translate(0, 100%);
    -webkit-animation: box2 1s linear infinite;
    animation: box2 1s linear infinite;
}

.boxes .box:nth-child(3) {
    -webkit-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
    -webkit-animation: box3 1s linear infinite;
    animation: box3 1s linear infinite;
}

.boxes .box:nth-child(4) {
    -webkit-transform: translate(200%, 0);
    transform: translate(200%, 0);
    -webkit-animation: box4 1s linear infinite;
    animation: box4 1s linear infinite;
}

.boxes .box > div {
    background: #71c73e;
    --translateZ: 15.5px;
    --rotateY: 0deg;
    --rotateX: 0deg;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #71c73e;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    -webkit-transform: rotateY(var(--rotateY)) rotateX(var(--rotateX)) translateZ(var(--translateZ));
    transform: rotateY(var(--rotateY)) rotateX(var(--rotateX)) translateZ(var(--translateZ));
}

.boxes .box > div:nth-child(1) {
    top: 0;
    left: 0;
    background: #71c73e;
}

.boxes .box > div:nth-child(2) {
    background: #71c73e;
    right: 0;
    --rotateY: 90deg;
}

.boxes .box > div:nth-child(3) {
    background: #1f493f;
    --rotateX: -90deg;
}

.boxes .box > div:nth-child(4) {
    background: #eff4ff;
    top: 0;
    left: 0;
    --translateZ: -90px;
}

@keyframes box1 {
    0%,
    50% {
        transform: translate(100%, 0);
    }

    100% {
        transform: translate(200%, 0);
    }
}

@keyframes box2 {
    0% {
        transform: translate(0, 100%);
    }

    50% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(100%, 0);
    }
}

@keyframes box3 {
    0%,
    50% {
        transform: translate(100%, 100%);
    }

    100% {
        transform: translate(0, 100%);
    }
}

@keyframes box4 {
    0% {
        transform: translate(200%, 0);
    }

    50% {
        transform: translate(200%, 100%);
    }

    100% {
        transform: translate(100%, 100%);
    }
}

// preload end

// swiper slide start

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    // object-fit: cover;
}

.swiper {
    margin-left: auto;
    margin-right: auto;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #007aff !important;
}

.swiper-pagination-bullet {
    background-color: white !important;
}

// swiper slide end

// ==============================|| LIGHT BOX ||============================== //
.slick-arrow:before {
    color: $grey500;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //
nav {
    .scrollbar-container {
        .ps__rail-y {
            &:hover > .ps__thumb-y,
            &:focus > .ps__thumb-y,
            &.ps--clicking .ps__thumb-y {
                background-color: $grey500;
                width: 5px;
            }
        }

        .ps__thumb-y {
            background-color: $grey500;
            border-radius: 6px;
            width: 5px;
            right: 0;
        }
    }

    .scrollbar-container.ps,
    .scrollbar-container > .ps {
        &.ps--active-y > .ps__rail-y {
            width: 5px;
            background-color: transparent !important;
            z-index: 999;

            &:hover,
            &.ps--clicking {
                width: 5px;
                background-color: transparent;
            }
        }

        &.ps--scrolling-y > .ps__rail-y,
        &.ps--scrolling-x > .ps__rail-x {
            opacity: 0.4;
            background-color: transparent;
        }
    }
}

.sports-items {
    .scrollbar-container {
        .ps__rail-x {
            &:hover > .ps__thumb-x,
            &:focus > .ps__thumb-x,
            &.ps--clicking .ps__thumb-x {
                background-color: $grey500;
                height: 7px;
            }
        }

        .ps__thumb-x {
            background-color: $grey500;
            border-radius: 6px;
            height: 7px;
            right: 0;
        }
    }

    .scrollbar-container.ps,
    .scrollbar-container > .ps {
        &.ps--active-x > .ps__rail-x {
            height: 7px;
            background-color: transparent !important;
            z-index: 999;

            &:hover,
            &.ps--clicking {
                height: 7px;
                background-color: transparent;
            }
        }

        &.ps--scrolling-x > .ps__rail-x,
        &.ps--scrolling-y > .ps__rail-y {
            opacity: 0.4;
            background-color: transparent;
        }
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }

    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }

    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }

    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }

    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }

    25% {
        transform: translateY(-10px);
    }

    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }

    25% {
        transform: translateX(-10px);
    }

    75% {
        transform: translateX(10px);
    }
}

.arrow-up {
    animation: bounceUp 1600ms infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.arrow-down {
    animation: bounceDown 1600ms infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

@keyframes bounceUp {
    50% {
        border: $success300 1px solid;
    }
}

@keyframes bounceDown {
    50% {
        border: $error700 1px solid;
    }
}

.text-ellipse {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.h6 {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 500;
    color: $darkTextPrimary;
    font-family: 'Roboto', sans-serif;
    line-height: 1.75;
}


.wallet-adapter-modal {
    z-index: 9999;
}

.sports-badge {
    & .MuiBadge-badge {
        background: $badge !important;
    }
}

header .login {
    background: #49bc8b !important;
    box-shadow: inset 0 0 4px #a3d0ef;
}

header .register {
    background: #eb3375;
    box-shadow: inset 0 0 4px #f189ae;
}

header .login,
header .register {
    min-width: 86px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    color: #fff;
    padding: 0 16px;
    border: none;
}

main .MuiTabs-indicator {
    display: none !important;
}

// sidebar CSS

.sidebar-item {
    -webkit-transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1) 1ms;
    transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1) 1ms;
    background-color: #00000000;
}

.sidebar-wrapper {
    overflow: initial;
}

.el-aside,
.el-header {
    box-sizing: border-box;
    flex-shrink: 0;
}

.left-menu-wrapper .activity-wrapper.close {
    padding: 16px 0px;

    li {
        border-top-left-radius: 0px;
        border-end-start-radius: 0px;
        grid-column: 1/-1;
        align-items: center;
        display: flex;

        img {
            position: unset !important;
            width: 40px;
        }
    }
}

.left-menu-wrapper {
    z-index: 99;
    transition: width 0.2s linear;
    height: 100%;
    background-color: #21252e;
    position: relative;
    display: flex;
    flex-direction: column;
}

.left-menu-wrapper .left-menu-content {
    flex: 1;
    overflow-y: auto;
    padding-bottom: 30px;
}

li,
ol,
ul {
    list-style: none;
}

.left-menu-wrapper ul.activity-wrapper {
    padding: 16px 10px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px 10px;
    margin-top: 0px;
}

.left-menu-wrapper ul.activity-wrapper > li {
    min-height: 64px;
    border-radius: 12px;
    cursor: pointer;
    padding: 6px 10px;
    position: relative;
}

.left-menu-wrapper ul.activity-wrapper > li img {
    position: absolute;
    right: 10px;
    top: 6px;
}

.left-menu-wrapper ul.activity-wrapper > li:first-child {
    width: 100%;
    grid-column: 1/-1;
    background: linear-gradient(102.41deg, rgba(252, 51, 104, 0.9) 2.22%, rgba(34, 40, 53, 0.9) 98.13%);
}

.left-menu-wrapper ul.activity-wrapper > li h1 {
    color: #ffe5ee;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0.3rem;
}

.left-menu-wrapper ul.activity-wrapper > li p {
    color: #fff;
    font-size: 11px;
}

.left-menu-wrapper ul.activity-wrapper > li > span {
    color: #fff007;
    font-size: 11px;
}

.left-menu-wrapper ul.activity-wrapper > li:first-child img {
    position: absolute;
    right: 10px;
    top: 6px;
}

.left-menu-wrapper ul.activity-wrapper > li:nth-child(2) {
    background: linear-gradient(102.41deg, rgba(65, 217, 134, 0.9) 2.22%, rgba(34, 40, 53, 0.9) 98.13%);
}

.left-menu-wrapper ul.activity-wrapper > li:nth-child(2) img {
    right: -15px;
    top: -20px;
}

.left-menu-wrapper ul.activity-wrapper > li:nth-child(3) {
    background: linear-gradient(102.41deg, rgba(136, 65, 243, 0.9) 2.22%, rgba(34, 40, 53, 0.9) 98.13%);
}

.left-menu-wrapper ul.activity-wrapper > li:nth-child(3) > span {
    color: #ffbe08;
}

.left-menu-wrapper ul.activity-wrapper > li:nth-child(3) img {
    right: 3px;
    top: -10px;
}

.left-menu-wrapper ul.activity-wrapper > li:nth-child(4) {
    background: linear-gradient(102.41deg, rgba(255, 207, 63, 0.9) 2.22%, rgba(34, 40, 53, 0.9) 98.13%);
}

.left-menu-wrapper ul.activity-wrapper > li:nth-child(4) span {
    color: #eba620;
}

.left-menu-wrapper ul.activity-wrapper > li:nth-child(4) img {
    right: -15px;
    top: -3px;
}

.left-menu-wrapper ul.activity-wrapper > li:nth-child(5) {
    background: linear-gradient(102.41deg, rgba(0, 176, 255, 0.9) 2.22%, rgba(34, 40, 53, 0.9) 98.13%);
}

.left-menu-wrapper ul.activity-wrapper > li:nth-child(5) span {
    color: #67cfff;
}

.left-menu-wrapper ul.activity-wrapper > li:nth-child(5) img {
    right: -8px;
    top: auto;
    bottom: -8px;
}

.left-menu-wrapper .list-wrapper {
    margin: 20px 0;
}

.left-menu-wrapper .list-wrapper h1 {
    display: flex;
    align-items: center;
    padding: 0 12px;
    font-size: 16px;
    color: #ebefff;
    margin-bottom: 15px;
    margin-top: 10px;
}

.left-menu-wrapper .list-wrapper ul {
    margin-bottom: 6px;
    padding: 0;
}

.left-menu-wrapper .list-wrapper ul li {
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    cursor: pointer;
    position: relative;
}

.left-menu-wrapper .list-wrapper ul li img {
    width: 20px;
    margin-right: 6px;
}

.left-menu-wrapper .list-wrapper ul li span {
    font-size: 16px;
    color: #7d8494;
}

li,
ol,
ul {
    list-style: none !important;
}

.left-menu-wrapper .list-wrapper ul li:hover {
    background: linear-gradient(90deg, #018ef0, #1dbaf2);
    border-radius: 0 3px 3px 0;
}

.left-menu-wrapper .list-wrapper ul li:hover span {
    color: #fff;
}

.left-menu-wrapper .list-wrapper ul li:hover:after {
    content: '';
    display: flex;
    width: 6px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background: linear-gradient(180deg, #fbd03b, #fa7800 73.96%);
    border-radius: 0 3px 3px 0;
}

// first Page CSS

.firstPageSlider .swiper-wrapper {
    height: 90%;
}

.firstPageSlider .swiper-pagination {
    bottom: 0 !important;
}

// .firstPageSlider .swiper-pagination .swiper-pagination-bullet-active {
//     background: #fff !important;
// }

.firstPageSlider .swiper-pagination .swiper-pagination-bullet {
    width: 16px;
    height: 4px;
    border-radius: 0;
    background: #8a8c8f;
    margin: 0 2px;
    opacity: 1;
}

.activity-box {
    margin-top: 30px;
    width: 100%;
    height: 260px;
    padding: 20px;
    position: relative;
    background: #21252e;
    box-shadow: inset 0 0 4px #455069;
    border-radius: 14px;
}

.activity-box .activity-wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: absolute;
    bottom: 20px;
}

.activity-box .activity-wrap .activity-item:hover {
    margin-bottom: 10px !important;
}

.activity-box .activity-wrap .activity-item {
    width: 380px;
    position: relative;
    cursor: pointer;
    transition: all 0.3s linear;
}

.activity-box .activity-wrap .activity-item:first-child {
    display: flex;
    height: 195px;
    background: url(../images/banner/active_bg1.png) no-repeat;
    background-size: 100% 100%;
}

.activity-box .activity-wrap .activity-item:nth-child(2) {
    display: flex;
    margin: 0 10px;
    height: 160px;
    background: url(../images/banner/active_bg2.png) no-repeat;
}

.activity-box .activity-wrap .activity-item:nth-child(3) {
    margin: 0 10px;
    height: 160px;
    background: url(../images/banner/active_bg3.png) no-repeat;
    display: flex;
}

.activity-wrap .activity-item:first-child .left {
    width: 142px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 14px;
    height: 100%;
}

.activity-box .activity-wrap .activity-item:first-child .con {
    margin-left: 10px;
    margin-top: 70px;
}

.activity-box .activity-wrap .activity-item:first-child .con h1 {
    font-size: 22px;
    line-height: 30px;
    color: #f74;
    display: flex;
    align-items: center;
    margin-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.activity-box .activity-wrap .activity-item:first-child .con p {
    margin-top: 5px;
    font-size: 18px;
    line-height: 22px;
    color: #4fe227;
}

.activity-box .activity-wrap .activity-item:first-child .con span {
    display: inline-block;
    text-align: center;
    min-width: 120px;
    position: absolute;
    bottom: 16px;
    height: 30px;
    line-height: 30px;
    background: linear-gradient(90deg, #0790f2, #1dbaf2);
    box-shadow: 0 0 2px #222733, inset 0 0 6px #0790f2;
    border-radius: 8px;
    color: #fff;
    user-select: text;
}

.activity-box .activity-wrap .activity-item:first-child .hot,
.activity-box .activity-wrap .activity-item:first-child .new {
    top: 36px;
    position: absolute;
    right: 0;
}

.activity-box .activity-wrap .activity-item:nth-child(2) .left {
    position: relative;
    height: 100%;
    width: 170px;
}

.activity-box .activity-wrap .activity-item:nth-child(2) .left img {
    position: absolute;
    bottom: 0;
    left: 0;
}

.activity-box .activity-wrap .activity-item:nth-child(2) .con {
    flex: 1;
    margin-top: 27px;
}

.activity-box .activity-wrap .activity-item:nth-child(2) .con h1 {
    font-size: 22px;
    line-height: 30px;
    color: #f4d16e;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.activity-box .activity-wrap .activity-item:nth-child(2) .con p {
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    margin-top: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.activity-box .activity-wrap .activity-item:nth-child(2) .con span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
    height: 30px;
    background: linear-gradient(90deg, #ef5600, #ffc700);
    box-shadow: 0 0 2px #c91441;
    border-radius: 8px;
    position: absolute;
    bottom: 16px;
    left: 170px;
}

.activity-box .activity-wrap .activity-item:nth-child(2) .hot,
.home-wrapper .main-content .activity-box .activity-wrap .activity-item:nth-child(2) .new {
    top: 0;
}

.activity-box .activity-wrap .activity-item:nth-child(3) .left {
    position: relative;
    height: 100%;
}

.activity-box .activity-wrap .activity-item:nth-child(3) .left img {
    position: absolute;
    bottom: 0;
    left: 0;
    display: none;
}

.activity-box .activity-wrap .activity-item:nth-child(3) .con {
    flex: 1;
    margin-top: 27px;
    margin-left: 170px;
}

.activity-box .activity-wrap .activity-item:nth-child(3) .con .con-bg {
    background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.297) 22.71%, rgba(0, 0, 0, 0.9));
    padding-bottom: 5px;
}

.activity-box .activity-wrap .activity-item:nth-child(3) .con h1 {
    font-size: 22px;
    line-height: 30px;
    color: #f4d16e;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.activity-box .activity-wrap .activity-item:nth-child(3) .con p {
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    margin-top: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.activity-box .activity-wrap .activity-item:nth-child(3) .con p > em {
    color: #a4f41c;
}

.activity-box .activity-wrap .activity-item:nth-child(3) .con span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 30px;
    background: linear-gradient(90deg, #95c000, #24a44b);
    box-shadow: 0 0 2px #404656;
    border-radius: 8px;
    position: absolute;
    bottom: 16px;
}

.activity-box .activity-wrap .activity-item:nth-child(3) .hot,
.home-wrapper .main-content .activity-box .activity-wrap .activity-item:nth-child(3) .new {
    top: 0;
}

.game_coin {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-right: 10px;
    padding: 0 5px 0 0;
}

.tab-box {
    // height: 72px;
    padding: 5px 10px;
    background: #21252e;
    box-shadow: inset 0 0 4px #455069;
    border-radius: 14px;
    // margin: 30px 0 20px;
    overflow: hidden;

    margin-right: 384px;
}

@media screen and (max-width: 756px) {
    .tab-box {
        display: none;
    }

    .active_tab_box {
        display: none;
    }
}

.tab-box-header {
    margin: 0px 75px 0 0;
    padding: 3px 0;
}

.tab-box button {
    display: inline-block;
    min-width: 163px;
    height: 52px;
    line-height: 52px;
    text-align: center;
    border-radius: 14px;
    color: #7d8494;
    cursor: pointer;
    margin-right: 20px;
    margin: 0;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    gap: 2px;
    padding: 10px;
}

.tab-box button img,
.tab-box button svg {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-right: 5px;
}

.tab-box div,
.tab-box button {
    // height: 100%;
    min-height: 0 !important;
}

.tab-box div button.Mui-selected,
.tab-box div button:hover {
    color: #fff;
    background: linear-gradient(90deg, #0790f2, #1dbaf2);
    box-shadow: 0 0 2px #222733, inset 0 0 6px #0790f2;
}

.lobby-category-item-wrapper .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.lobby-category-item-wrapper .title-wrapper .left {
    display: flex;
}

.lobby-category-item-wrapper .title-wrapper .left img,
.lobby-category-item-wrapper .title-wrapper .left svg {
    width: 36px;
    height: 36px;
    margin-right: 10px;
}

.lobby-category-item-wrapper .title-wrapper .left h1 {
    text-transform: capitalize;
    font-size: 22px;
    line-height: 31px;
    display: flex;
    align-items: center;
    color: #ebefff;
}

.lobby-category-item-wrapper .content-wrapper {
    margin: 10px 0 20px;
    position: relative;
}

.lobby-category-item-wrapper .content-wrapper .swiper-page-wrapper {
    position: absolute;
    top: -46px;
    right: 0;
    width: 100px;
    min-height: 50px;
}

.lobby-category-item-wrapper .content-wrapper .swiper-wrapper .swiper-slide:hover {
    transform: translateY(-10px);
    cursor: pointer;
}

.lobby-category-item-wrapper .content-wrapper .swiper-wrapper .swiper-slide {
    width: 190px;
    height: 100%;
    overflow: hidden;
    border-radius: 15px;
    cursor: pointer;
    position: relative;
    transition: all 0.2s linear;
    margin-top: 10px;
}

.lobby-category-item-wrapper .content-wrapper .swiper-page-wrapper button {
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background: #21252e;
    border: 1.4px solid #42485a;
    border-radius: 10px;
    color: #9ba0a8;
    cursor: pointer;
    position: absolute;
    top: 0;
    z-index: 10;
}

.lobby-category-item-wrapper .content-wrapper .swiper-page-wrapper button.prev-btn {
    left: 10px;
    right: auto;
}

.lobby-category-item-wrapper .content-wrapper .swiper-page-wrapper button.next-btn {
    right: 10px;
    left: auto;
}

.lobby-category-item-wrapper .content-wrapper .swiper-page-wrapper button:hover {
    background: #0790f2;
    border: 1.4px solid #4aa5ef;
    color: #fff;
}

.hover-wrapper:hover {
    opacity: 1;
    transition: all 0.2s linear;
    transition-delay: 0.3s;
}

.hover-wrapper {
    z-index: 5;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(27, 34, 51, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.hover-wrapper .title {
    padding: 0 5px;
    font-size: 22px;
    text-align: center;
    color: #fff;
}

.hover-wrapper .pause {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #ed1d49;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hover-wrapper .pause svg {
    width: 100%;
    height: 100%;
}

.hover-wrapper .go {
    font-size: 13px;
    color: #fff;
}

.active_provider {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid;
    position: fixed;
    z-index: 100;
    width: 80%;
    padding: 10px;
    border-radius: 18px;
    background: #29303e;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.12) 0px 1px 2px 0px, rgba(255, 255, 255, 0.04) 0px 1px 0px 0px inset;
    margin-bottom: 10px;

    label {
        font-size: 22px;
    }
}

.lobby-wrapper .vip-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.lobby-wrapper .vip-box .vip-wrap {
    width: 100%;
    height: 280px;
    box-shadow: inset 0 0 4px #455069;
    border-radius: 14px;
    background: url(../images/banner/vip_bg.png) no-repeat;
    padding: 43px 20px 20px 156px;
    cursor: pointer;
}

.lobby-wrapper .vip-box .vip-wrap h1 {
    font-size: 46px;
    background: linear-gradient(180deg, #fff, #f7e47a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.lobby-wrapper .vip-box .vip-wrap p {
    font-size: 30px;
    color: #fff;
}

.lobby-wrapper .vip-box .vip-wrap span {
    display: inline-block;
    color: #fff;
    margin-top: 22px;
    min-width: 150px;
    height: 40px;
    line-height: 44px;
    text-align: center;
    background: linear-gradient(90deg, #183cba, #a60cbc);
    border-radius: 8px;
}

.my-bet-box div.tabs-box {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    height: 46px;
    background: #21252e;
    border-radius: 12px;
    margin-top: 20px;
    box-shadow: inset 0 0 2px #191d26;
}

.my-bet-box div.tabs-box button {
    height: 100%;
    display: flex;
    width: 125px;
    align-items: center;
    color: #fff;
    font-size: 16px;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    margin-right: 5px;
}

.my-bet-box div.tabs-box button.Mui-selected,
.my-bet-box div.tabs-box button:hover {
    height: 100%;
    color: #fff;
    background: #2e364a;
    border-radius: 12px;
    box-shadow: 0 0 2px #222733, inset 0 0 6px #0790f2;
}

.my-bet-box .my-bet-con tbody.item-wrapper tr,
.my-bet-box .my-bet-con .my-bet-wrapper tbody.item-wrapper tr {
    min-height: 45px;
    align-items: center;
    justify-content: space-between;
    background: #19202e;
    border-radius: 10px;
    padding: 0 20px;
    width: 100%;
}

.my-bet-box .my-bet-con tbody tr,
.my-bet-box .my-bet-con tbody th,
.my-bet-box .my-bet-con tbody td {
    border: 0px;
}

.my-bet-box .my-bet-con tbody.item-wrapper tr:nth-child(2n),
.my-bet-box .my-bet-con .my-bet-wrapper tbody.item-wrapper tr:nth-child(2n) {
    background: #121721;
}

.my-bet-box .my-bet-con tbody.item-wrapper tr th.game-name,
.my-bet-box .my-bet-con .my-bet-wrapper tbody.item-wrapper tr th.game-name {
    height: 45px;
    padding: 0;
    flex: 2.5;
    display: flex;
    align-items: center;
}

.my-bet-box .my-bet-con tbody.item-wrapper tr th.game-name .icon img,
.my-bet-box .my-bet-con .my-bet-wrapper tbody.item-wrapper tr th.game-name .icon img {
    width: 25px;
    height: auto;
}

.my-bet-box .my-bet-con tbody.item-wrapper tr th.game-name .desc,
.my-bet-box .my-bet-con .my-bet-wrapper tbody.item-wrapper tr th.game-name .desc {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    margin-left: 10px;
}

.my-bet-box .my-bet-con tbody.item-wrapper tr th.game-name .desc span,
.my-bet-box .my-bet-con .my-bet-wrapper tbody.item-wrapper tr th.game-name .desc span {
    width: auto;
    height: 100%;
    color: #c8ccd6;
    font-size: 12px;
}

.my-bet-box .my-bet-con tbody.item-wrapper tr td.create-time,
.my-bet-box .my-bet-con .my-bet-wrapper tbody.item-wrapper tr td.create-time {
    height: 45px;
    padding: 0;
    flex: 2;
    font-size: 16px;
    color: #707c93;
    text-align: center;
}

.my-bet-box .my-bet-con tbody.item-wrapper tr td.bet-money,
.my-bet-box .my-bet-con .my-bet-wrapper tbody.item-wrapper tr td.bet-money {
    height: 45px;
    padding: 0;
    flex: 2;
    font-size: 16px;
    color: #707c93;
    text-align: center;
    align-items: center;
    justify-content: flex-end;
}

.my-bet-box .my-bet-con tbody.item-wrapper tr td.bet-money img,
.my-bet-box .my-bet-con .my-bet-wrapper tbody.item-wrapper tr td.bet-money img {
    width: 25px;
    margin-left: 10px;
    margin-right: 20px;
}

.my-bet-box .my-bet-con tbody.item-wrapper tr td.multiple,
.my-bet-box .my-bet-con .my-bet-wrapper tbody.item-wrapper tr td.multiple {
    height: 45px;
    padding: 0;
    flex: 2;
    text-align: center;
    color: #707c93;
}

.my-bet-box .my-bet-con tbody.item-wrapper tr td.profit,
.my-bet-box .my-bet-con .my-bet-wrapper tbody.item-wrapper tr td.profit {
    height: 45px;
    padding: 0;
    flex: 2.5;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.format-money-wrapper {
    height: 100%;
}

.my-bet-box .my-bet-con tbody.item-wrapper tr td.profit img,
.my-bet-box .my-bet-con .my-bet-wrapper tbody.item-wrapper tr td.profit img {
    width: 25px;
    margin-left: 10px;
}

.footer-wrapper {
    padding: 30px 0 10px;
    background: #21252e;
    box-shadow: 0 0 4px #455069;
    border-radius: 15px 15px 0 0;
}

.footer-wrapper .wrapper-content {
    width: 1200px;
    padding: 0 !important;
    margin: 0 auto;
}

.footer-wrapper .wrapper-content .row {
    display: flex;
    align-items: center;
    width: 100%;
}

.footer-wrapper .wrapper-content .row_2 {
    margin-bottom: 32px;
    text-align: center;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.footer-wrapper .wrapper-content .row_1 {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.footer-wrapper .wrapper-content .row_2 .title {
    width: 100%;
    font-size: 35px;
    font-family: Microsoft YaHei UI;
    font-weight: 700;
    color: #fff;
}

.footer-wrapper .wrapper-content .row_2 .title img {
    height: 40px;
}

.footer-wrapper .wrapper-content .row_2 .sub_text {
    font-size: 16px;
    font-family: Microsoft YaHei UI;
    font-weight: 400;
    color: #fff;
    margin-bottom: 30px;
}

.footer-wrapper .wrapper-content .row_2 .num {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-family: Microsoft YaHei UI;
    font-weight: 700;
    color: #fff;
    padding: 11px 0;
}

.footer-wrapper .wrapper-content .row_2 .num > img {
    margin-right: 5px;
    width: 20px;
    aspect-ratio: auto 20 / 20;
    height: 20px;
}

.footer-wrapper .wrapper-content .row_1 .item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: left;
}

.footer-wrapper .wrapper-content .row_1 .item .sub_item {
    display: flex;
    flex-direction: column;
}

.footer-wrapper .wrapper-content .row_1 .item .sub_item div {
    margin-bottom: 10px;
    font-size: 14px;
    font-family: Microsoft YaHei UI;
    font-weight: 400;
    color: #97a1b5;
}

.footer-wrapper .wrapper-content .row_1 .item .sub_item .title {
    margin-bottom: 18px;
    font-size: 16px;
    font-family: Microsoft YaHei UI;
    font-weight: 400;
    color: #fff;
}

.footer-wrapper .wrapper-content .row_5 {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
}

.footer-wrapper .wrapper-content .row_5 .lang {
    box-shadow: inset 0 0 4px #455069;
    border-radius: 10px;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.footer-wrapper .wrapper-content .row_5 .lang button,
.footer-wrapper .wrapper-content .row_5 .lang button:hover {
    background-color: #00000000 !important;
    padding: 0;
}

.footer-wrapper .wrapper-content .row_5 .lang button img {
    width: 16px;
    height: 16px;
    margin-right: 6px;
}

.footer-wrapper .wrapper-content .row_5 .share {
    display: flex;
}

.footer-wrapper .wrapper-content .row_5 .share > div {
    margin-left: 10px;
}

.footer-wrapper .wrapper-content .row_5 .share > div img {
    cursor: pointer;
    width: 26px;
    height: 26px;
}

// Login Page

.el-dialog__wrapper {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.el-dialog__wrapper {
    background: rgba(21, 25, 31, 0.7) !important;
}

.el-dialog__wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0;
}

.el-dialog {
    background-color: rgba(0, 0, 0, 0);
}

.el-dialog--center {
    text-align: center;
}

.el-dialog {
    position: relative;
    margin: 0 auto 50px;
    border-radius: 2px;
    // box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
    box-sizing: border-box;
    width: 50%;
}

.el-dialog__body {
    background-color: #00000000;
    padding: 0;
    border-radius: 13px;
}

.el-dialog--center .el-dialog__body {
    text-align: initial;
    padding: 0;
}

.el-dialog__body {
    padding: 30px 20px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
}

.content {
    display: flex;
    flex-direction: row;
}

.content .login-bg {
    width: 349px;
    border-radius: 13px;
    background: url(../images/new/new_3.png) no-repeat 50%;
    background-size: cover;
}

.content .right {
    position: relative;
    text-align: center;
    width: 505px;
    padding: 20px 60px;
    box-sizing: content-box;
}

.content .right .close {
    position: absolute;
    right: 30px;
    top: 20px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    background: #0790f2;
    border-radius: 10px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.el-tabs {
    margin-top: 40px;
}

.content .right .other {
    font-size: 14px;
    font-family: Microsoft YaHei UI;
    font-weight: 400;
    color: #98a2b6;
    line-height: 18px;
}

.el-tabs__header {
    margin-bottom: 20px;
    padding: 0;
    position: relative;
    margin: 0 0 15px;
    border: 0;
    height: 40px;
}

.el-tabs__nav-wrap {
    overflow: hidden;
    margin-bottom: -1px;
    position: relative;
}

.el-tabs__nav {
    display: flex;
    min-height: 0;
    height: 40px;
    align-items: center;
    background: #15191f;
    box-shadow: inset 0 0 2px #191d26;
    border-radius: 12px;
    padding-bottom: 0 !important;
    white-space: nowrap;
    position: relative;
    transition: transform 0.3s;
    float: left;
    z-index: 2;
}

.el-tabs__item {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 117px;
    min-height: 40px;
    font-size: 14px;
    font-family: Microsoft YaHei UI;
    font-weight: 400;
    padding: 0;
    color: #7d8494;
    box-sizing: border-box;
    line-height: 40px;
    list-style: none;
    position: relative;
}

.el-tabs__nav .Mui-selected {
    color: #fff;
    background: #2e364a;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25), inset 0 0 6px #0790f2;
    border-radius: 12px;
}

.el-tabs__nav .MuiTabs-indicator {
    display: none;
}

.other {
    font-size: 14px;
    font-family: Microsoft YaHei UI;
    font-weight: 400;
    color: #98a2b6;
    line-height: 18px;
}

.content .right .other .forgetPwd {
    cursor: pointer;
    font-size: 14px;
    color: #fff;
    margin-bottom: 16px;
}

.content .right .other .text {
    margin-bottom: 20px;
}

.content .right .other .text,
.content .right .other {
    font-size: 14px;
    font-family: Microsoft YaHei UI;
    font-weight: 400;
    color: #98a2b6;
    line-height: 18px;
}

.third-login-wrapper {
    margin-bottom: 16px;
}

.third-login-wrapper > ul {
    display: flex;
    align-items: center;
    justify-content: center;
}

.third-login-wrapper > ul > li {
    width: 44px;
    height: 44px;
    position: relative;
    margin: 0 10px;
    cursor: pointer;
    color: #fff;
    overflow: hidden;
}

.third-login-wrapper > ul > li img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.content .right .other .agreement {
    font-size: 14px;
    color: #7d8494;
    line-height: 18px;
    margin-top: 16px;
}

.content .right .other .agreement > span {
    cursor: pointer;
    color: #fff;
}

content .right .login {
    width: 100%;
}

.el-form-item {
    margin-bottom: 20px;
}

.login .el-form-item__label {
    line-height: 20px;
    color: #7d8494;
    margin-bottom: 10px;
    font-size: 16px;
}

.el-form-item__label {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
}

.el-form-item__content {
    line-height: 40px;
    position: relative;
    font-size: 14px;
}

.el-input {
    position: relative;
    font-size: 14px;
}

.el-color-picker__icon,
.el-input,
.el-textarea {
    display: block;
    width: 100%;
}

.login .el-form-item.is-error .el-input__inner {
    border-color: #fe2247;
}

.login .is-error .el-input .el-input__inner {
    border: 1px solid #c03059;
}

.login .el-input__inner {
    color: #fff;
    background: #15191f;
    box-shadow: inset 0 0 2px #191d26;
    border-radius: 12px;
    border: 0;
}

.el-input--suffix .el-input__inner {
    padding-right: 30px;
}

.el-input__inner {
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
}

.el-input__inner:before {
    border-bottom: 0 !important;
}

.el-input__inner:after {
    border-bottom: 0 !important;
}

.el-input__inner input {
    height: 75%;
}

.login .el-form-item__error {
    color: #c03059;
    left: auto;
    text-align: right;
    font-size: 16px;
}

.is-error .el-form-item__error {
    display: block;
    color: #f56c6c;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
    position: absolute;
    top: 100%;
    left: 0;
}

.el-input__suffix {
    height: 100%;
    right: 5px;
    transition: all 0.3s;
    pointer-events: none;
}

.el-input__prefix,
.el-input__suffix {
    position: absolute;
    top: 45px;
    text-align: center;
}

.el-input__prefix,
.el-input__suffix {
    -webkit-transition: all 0.3s;
    color: #c0c4cc;
}

.el-input__suffix-inner {
    pointer-events: all;
}

.el-input__suffix-inner img {
    width: 16.5px;
    margin-right: 6px;
    cursor: pointer;
}

.login button {
    width: 100%;
    background: #49bc8b;
    border-radius: 10px;
    font-size: 18px;
    font-family: Microsoft YaHei UI;
    color: #fff;
    line-height: 18px;
    border: none;
    height: 45px;
    margin-top: 10px;
}

.el-button {
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    border: 1px solid #dcdfe6;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: 0.1s;
    font-weight: 500;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 4px;
}

.el-button:hover {
    background-color: rgb(33 150 243 / 83%);
}

.Register .el-form-item .el-form-item__label {
    line-height: 20px;
    color: #7d8494;
    margin-bottom: 10px;
    font-size: 16px;
    text-align: left;
}

.Register .el-input__inner {
    background: #15191f;
    box-shadow: inset 0 0 2px #191d26;
    border-radius: 12px;
    border: 0;
}

.Register .el-form-item__error {
    color: #c03059;
    font-size: 16px;
}

.Register .item-code-box {
    display: flex;
    flex-direction: column;
}

.Register .el-form-item .el-form-item__label {
    line-height: 20px;
    color: #7d8494;
    margin-bottom: 10px;
    font-size: 16px;
    text-align: left;
}

.Register .item-code-box.is-error .code {
    border: 1px solid #c03059;
}

.Register .code {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #15191f;
    box-shadow: inset 0 0 2px #191d26;
    border-radius: 12px;
}

.Register .code .getCodeBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 84px;
    padding: 0 20px;
    height: 32px;
    background: #0790f2;
    color: #fff;
    border-radius: 10px;
    margin: 5px;
}

.Register .btn_register {
    width: 100%;
    background: #49bc8b;
    border-radius: 10px;
    font-size: 18px;
    font-family: Microsoft YaHei UI;
    color: #fff;
    line-height: 18px;
    border: none;
    height: 45px;
    margin-top: 10px;
}

.Register .el-form-item.is-error .el-input__inner {
    border-color: #fe2247;
}

.Register .is-error .el-input .el-input__inner {
    border: 1px solid #c03059;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.tab-box .ps .ps__rail-x:hover {
    background-color: #00000000 !important;
    opacity: 0.9;
}

.tab-box .ps__rail-x:hover > .ps__thumb-x {
    height: 4px !important;
}

.tab-box .MuiTabs-flexContainer {
    overflow-x: auto;
    width: fit-content;
}

.tab-box .MuiTabs-flexContainer::-webkit-scrollbar {
    height: 4px;
}

.game-box ul.tabs-content-wrapper {
    padding: 0 0.6rem 0.6rem;
    width: 100%;
}

.game-box ul.tabs-content-wrapper li .other-game {
    margin-top: 0.4rem;
}

.game-box ul.tabs-content-wrapper li .other-game .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.game-box ul.tabs-content-wrapper li .other-game .title-wrapper img {
    width: 36px;
    margin-right: 10px;
}

.game-box ul.tabs-content-wrapper li .other-game .title-wrapper span {
    text-transform: capitalize;
    font-size: 22px;
    line-height: 31px;
    display: flex;
    align-items: center;
    color: #ebefff;
}

.game-box .view-more span {
    margin-right: 0px;
}

// Mobile responsive

@media only screen and (max-width: 767px) {
    .active-item-wrapper {
        margin: 1.1rem 0.6rem 0.8rem;
    }

    .active-item-wrapper ul {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.6rem 0.3rem;
        margin: 0;
        padding: 0;
    }

    .active-item-wrapper ul li {
        position: relative;
        height: 6.6rem;
        display: flex;
    }

    .active-item-wrapper ul li:first-child {
        grid-column: 1/-1;
        width: 100%;
        background: url(../images/banner/active_bg2.png) no-repeat;
        background-size: 100% 100%;
    }

    .active-item-wrapper ul li:first-child .img-box {
        position: absolute;
        bottom: 0;
        left: 1.1rem;
    }

    .active-item-wrapper ul li:first-child .img-box > img {
        width: 7.3rem;
    }

    .active-item-wrapper ul li .text-box {
        color: #fff;
    }

    .active-item-wrapper ul li:first-child .text-box {
        margin-left: 9.3rem;
        margin-top: 2rem;
    }

    .active-item-wrapper ul li:first-child .text-box h1 {
        font-size: 1rem;
        line-height: 1rem;
        color: #ffbc00;
    }

    .active-item-wrapper ul li:first-child .text-box p {
        margin-top: 0.3rem;
        font-size: 0.8rem;
        line-height: 1.1rem;
        color: #fff;
    }

    .active-item-wrapper ul li .hot-new {
        position: absolute;
        top: 0;
        right: 0;
    }

    .active-item-wrapper ul li .hot-new > img {
        width: 3rem;
    }

    .active-item-wrapper ul li:nth-child(2) {
        background: url(../images/banner/active_bg1_2.png) no-repeat;
        background-size: 100% 100%;
    }

    .active-item-wrapper ul li:nth-child(2) .img-box {
        margin-left: 0.6rem;
        margin-top: 1rem;
    }

    .active-item-wrapper ul li:nth-child(2) .img-box > img {
        width: 4rem;
    }

    .active-item-wrapper ul li:nth-child(2) .text-box {
        margin-left: 0.55rem;
        margin-top: 2.3rem;
    }

    .active-item-wrapper ul li:nth-child(2) .text-box h1 {
        font-size: 0.8rem;
        line-height: 0.8rem;
        color: #f74;
    }

    .active-item-wrapper ul li:nth-child(2) .text-box p {
        margin-top: 0.08rem;
        font-size: 0.7rem;
        line-height: 1rem;
        color: #4fe227;
    }

    .active-item-wrapper ul li:last-child {
        background: url(../images/banner/active_bg3.png) no-repeat;
        background-size: 100% 100%;
    }

    .active-item-wrapper ul li:last-child .text-box {
        margin-left: 5rem;
        margin-top: 1.1rem;
    }

    .active-item-wrapper ul li:last-child .text-box h1 {
        font-size: 0.8rem;
        line-height: 0.9rem;
        color: #ffbd00;
    }

    .active-item-wrapper ul li:last-child .text-box p {
        margin-top: 0.4rem;
        font-size: 0.7rem;
        line-height: 0.7rem;
        color: #fff;
    }

    .active-item-wrapper ul li:last-child .text-box p span {
        color: #a4f41c;
    }

    .tab-box {
        margin: 0.3rem 0.2rem 0;
        width: calc(100% - 0.4rem);
        overflow-x: auto;
        overflow-y: hidden;
        padding: 0;
        border: 0;
        height: 44px;
        background: #19202e;
    }

    .tab-box .tabs-wrapper {
        height: 88%;
    }

    .tab-box .tabs-wrapper .mask-right {
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        width: 0.99rem;
        height: 0.93rem;
        background: linear-gradient(-90deg, #121721, rgba(18, 23, 33, 0));
    }

    .tab-box .tabs-wrapper div.tabs-title-wrapper {
        margin: 0.3rem 0.2rem 0;
        width: calc(100% - 0.4rem);
        overflow-x: auto;
        overflow-y: hidden;
    }

    .tab-box .tabs-wrapper div.tabs-title-wrapper {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        display: flex;
        border-radius: 0.45rem;
        color: #98a2b6;
        background: #19202e;
    }

    .tab-box .tabs-wrapper div.tabs-title-wrapper button {
        padding: 0 0.8rem;
        font-size: 0.8rem;
        color: #a9a7a7;
        height: 100%;
        min-width: 82px;
        text-align: center;
        line-height: 1.9rem;
        display: flex;
        align-items: center;
        margin-right: 1rem;
        border-radius: 1.3rem;
        flex-direction: row;
    }

    .tab-box .tabs-wrapper div.tabs-title-wrapper button.Mui-selected,
    .tab-box .tabs-wrapper div.tabs-title-wrapper button:hover {
        color: #fff;
        background: #20293c;
        font-weight: 700;
    }



    /* Hide scrollbar for IE, Edge and Firefox */
    .tab-box {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    .lobby-wrapper {
        margin-top: 10px;
    }

    .lobby-category-item-wrapper .title-wrapper .left h1 {
        font-size: 17px;
    }

    .lobby-category-item-wrapper .title-wrapper .left img {
        width: 22px;
        height: 22px;
        margin: 10px;
    }

    .lobby-category-item-wrapper .content-wrapper .swiper-wrapper .swiper-slide {
        height: 100%;
    }

    .lobby-category-item-wrapper .content-wrapper .swiper-wrapper .swiper-slide .hover-wrapper {
        display: none;
    }

    .lobby-wrapper .vip-box .vip-wrap {
        background-size: 100% 100%;
        height: 127px;
        padding: 25px 2px 20px 15px;
    }

    .lobby-wrapper .vip-box .vip-wrap h1 {
        font-size: 18px;
    }

    .lobby-wrapper .vip-box .vip-wrap p {
        font-size: 12px;
    }

    .lobby-wrapper .vip-box .vip-wrap span {
        font-size: 12px;
        margin-top: 14px;
        min-width: 113px;
        height: 28px;
        line-height: 28px;
    }

    .my-bet-box div.tabs-box {
        width: 100%;
    }

    .my-bet-box div.tabs-box button {
        width: 32%;
        font-size: 14px;
    }

    .footer-wrapper {
        margin-bottom: 60px;
    }

    .footer-wrapper .wrapper-content {
        width: 100%;
    }

    .footer-wrapper .wrapper-content .row {
        flex-direction: column;
    }

    .footer-wrapper .wrapper-content .row_2 .title a {
        justify-content: center;
    }

    .footer-wrapper .wrapper-content .row_2 .sub_text {
        margin-top: 10px;
        font-size: 13px;
    }

    .footer-wrapper .wrapper-content .row_1 {
        gap: 50px;
    }

    .footer-wrapper .wrapper-content .row_5 {
        margin: 10px;
        justify-content: space-evenly;
    }

    .footer-wrapper .wrapper-content .row_5 .share > div {
        margin-left: 4px;
    }

    .footer-wrapper .wrapper-content .row_5 .share {
        align-items: center;
    }

    .left-menu-wrapper .MuiPaper-root {
        height: 84%;
    }

    .content .login-bg {
        display: none;
    }

    .content .right {
        padding: 20px 3%;
    }

    .el-dialog {
        width: 100% !important;
    }

    .content .right {
        width: 96% !important;
    }

    .el-dialog--center {
        margin-top: 6vh !important;
    }
}

.input_header div {
    background-color: white;
    color: black;

    svg {
        fill: #3e3e3e;
        right: 77px;
    }
}

.section_evo {
    margin-top: 10px;

    @media screen and (max-width: 767px) {
        padding: 0 14px;
    }

    .header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;

        img {
            width: 32px;

            @media screen and (max-width: 414px) {
                width: 20px;
            }
        }

        h3 {
            color: white;
            font-size: 18px;
            font-weight: 700;

            @media screen and (max-width: 414px) {
                font-size: 14px;
                font-weight: 600;
            }
        }

        span {
            color: yellow;
            font-size: 14px;

            @media screen and (max-width: 414px) {
                font-size: 10px;
            }
        }
    }

    .top_evol {
        top: -30px;

        @media screen and (max-width: 414px) {
            top: 16px;
        }

        @media screen and (max-width: 767px) {
            top: 0px;
        }
    }

    .bottom_evol {
        top: 266px;

        @media screen and (max-width: 767px) {
            top: 190px;
        }

        @media screen and (max-width: 414px) {
            top: 102px;
        }
    }
}

.list_game_image {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.padding_main {
    padding: 0 0px;
}

.new_game {
    padding-top: 14px;
}

.active_main {
    padding-right: 20px;
    padding-bottom: 10px;

    .active_main_item {
        cursor: pointer;
        display: flex;
        align-items: center;
        color: white;
        font-size: 20px;
        gap: 10px;
        padding-left: 12px;
        height: 40px;

        margin-top: 8px;

        img {
            // width: 28px !important;
        }

        div {
            font-size: 14px;
        }
    }

    .active_main_content {
        // padding-left: 12px;
        // width: 206px;
        :hover {
            // font-weight: bold;
            // background-image: linear-gradient(to right, rgba(105, 179, 1, 0.4), rgba(85, 89, 102, 0.4) 33%, rgba(85, 89, 102, 0.4));
            // color: rgb(245, 246, 247);
            // /* padding: 0; */
            // border-radius: 0px 20px 20px 0px;;
        }
    }
}

.main_language {
    padding-top: 80px;
}

@media screen and (max-width: 767px) {
    .logo_mobile {
        flex-direction: row-reverse;
        padding: 0 10px;
    }
}

.is_drager {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    img {
        width: 24px !important;
    }
}

.MuiTabs-flexContainer {
    gap: 16px;
}

@media screen and (max-width: 767px) {
    .box_header {
        display: none;
    }
}

.list_game_home {
    width: 100% !important;
}

.live_casino_list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;

    .text_item {
        width: 100;
    }
}

.live_home {
    padding: 20px 20px !important;
}

.text_item {
    text-align: center;
    white-space: nowrap;
    color: white;
    font-size: 14px;
}

.header_list {
    display: flex;
    gap: 20px;

    @media screen and (max-width: 767px) {
        display: none;
    }

    div {
        padding: 10px 20px;
        border-radius: 35px;
        border: 1px solid;
        width: 136px;
        cursor: pointer;
        text-align: center;
    }
}

.slot_game {
    margin-top: 30px;
}

.active_main_item_up {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 14px;

    div {
        color: white;
        font-weight: 600;
        font-size: 14px;
    }
}

.search-input-wrap-pc {
    width: 80%;
    height: 2.875rem;
    border-radius: 1.4375rem;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    border: 1px solid transparent;
    position: relative;
}

.dwaOxj {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: flex-start;
    background-color: rgb(28, 30, 34);
    border-radius: 1.4375rem;
    padding: 0px 0.875rem;
    height: 100%;
    width: 80%;
    border: 1px solid transparent;
    margin-left: 20px;
}

.dwaOxj input {
    width: 80%;
    height: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    margin-left: 0.375rem;
    position: relative;
    color: rgb(255, 255, 255);
    font-weight: bold;
}

.dwaOxj input:focus .dwaOxj {
    border: 1px solid rgb(93, 219, 28);
}

@media screen and (max-width: 767px) {
    .search-input-wrap-pc {
        display: none;
    }
}

.active_main_item img {
    width: 24px !important;
}

.active_main_item,
.active_main_item_up {
    cursor: pointer;

    div {
        font-size: 14px !important;
        font-weight: 400;
        margin-top: 6px;
        color: rgba(153, 164, 176, 0.8);
    }
}

.active_main_item:hover,
.active_main_item_up:hover {
    div {
        color: white;
    }
}

.btn_main_sidebar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgb(26, 28, 32);
    box-shadow: transparent 0px 2px 4px 0px;
    z-index: 103;
    position: fixed;
    top: 16px;
    left: 14px;
    transition: left 0.2s linear 0s;
}

.pc-anim {
    position: absolute;
    top: 100%;
    height: 100vh;
    padding: 0.75rem 0.625rem;
    transition: height 0.5s ease 0s;
}

.main_header {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
}

.result_search {
    position: absolute;
    width: 100%;
    top: 60px;
    padding: 12px 10px;
    transition: height 0.5s ease 0s;
}

.search-anim-enter {
    padding: 32px;
    border-radius: 1.25rem;
    background-color: rgba(25, 26, 30, 0.99);
    width: 100%;
    margin: 0px auto;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 6px 12px 0px;
}

.search-main::after {
    content: '';
    position: absolute;
    inset: 0px;
    opacity: 0.1;
    background-image: radial-gradient(circle at 50% 50%, rgb(246, 34, 120), rgba(28, 30, 34, 0) 48%);
    z-index: -1;
}

.require-wrod {
    margin: 0px 0px 16px;
    font-size: 12px;
    color: rgb(152, 167, 181);
    line-height: 1.125rem;
    text-align: center;
}

.item_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.openDrawr_search {
    width: calc(100% + 220px);
    right: 0;
}
.active_main_item:hover {
    font-weight: bold;
    background-image: linear-gradient(to right, rgba(105, 179, 1, 0.4), rgba(85, 89, 102, 0.4) 33%, rgba(85, 89, 102, 0.4));
    color: rgb(245, 246, 247);
    border-radius: 0 20px 20px 0;
}
// @media screen and (max-width: 716px) {
//     .logo_main{
//         display: none;
//     }
// }
.btn_close_main {
    position: absolute;
    top: 73px;
    z-index: 999;
    right: 15px;
}
.logo_main {
    width: 126px !important;
    margin-left: 20px;
    position: absolute;
    left: 60px;
    @media screen and (max-width: 916px) {
        margin-left: -50px !important;
    }
}
.main_title_provider {
    font-size: 12px !important;
}
.active_gameName {
    width: 100%;
    height: 174.7px;
}
.css-skm417-MuiToolbar-root {
    min-height: 0px;
}

html {
    font-size: 14px;
}
body {
    margin: 0;
    font-family: 'SCDream' !important;
    box-sizing: border-box;
    font-family: 'SCDream';
    background: #000000;
}
.live {
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.game-buttons_casino {
    padding: 2px 5px;
    transition: all 0.5s;
    font-size: 14px;
    font-weight: bold;
    color: #999;
    line-height: 30px;
    position: relative;
    background-color: #000;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 1px 1px #6c4829;
}
.tit {
    position: absolute;
    left: 30px;
    bottom: 8px;
}
.btn-play {
    position: absolute;
    right: 106px;
    bottom: 12px;
    width: 155px;
    height: 35px;
    border-radius: 5px;
    margin: auto;
    z-index: 4;
    box-shadow: 0 0 10px rgba(234, 209, 163, 0.5), 0 1px 2px rgba(0, 0, 0, 1), inset 0 0 0 1px #1d160e;
    opacity: 0;
    transform: scale(0);
    transition: all 0.3s;
    top: 0;
    padding: 3px 35px;
    font-size: 12px;
    background: #111111;
    border: 1px solid #2044fa;
    color: #2044fa !important;
    font-weight: bold;
    display: inline-block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.game-buttons_casino:hover .btn-play {
    opacity: 1;
    transform: scale(1);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.btn-play:hover {
    box-shadow: 0 0 15px rgba(234, 209, 163, 1), 0 1px 2px rgba(0, 0, 0, 1), inset 0 0 0 1px #1d160e;
    background: #2044fa;
    color: #ffffff !important;
}

.slot {
    padding: 10px;
    gap: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
.game-buttons_slot {
    padding: 5px;
    transition: all 0.5s;
    font-size: 14px;
    font-weight: bold;
    color: #999;
    line-height: 30px;
    position: relative;
    background-color: #000;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 1px 1px #6c4829;
}
.game-buttons_slot:hover .btn-play {
    opacity: 1;
    transform: scale(1);
}
.btn_slot {
    right: 56px;
}

/* login form */
.login_input {
    margin-bottom: 10px;
    /* background: #302e36; */
    border: 1px solid #585858;
    border-radius: 0;
    padding: 6px 12px;
    padding-left: 40px;
    color: #cccccc;
    display: block;
    width: 100%;
    height: 34px;

    font-size: 14px;
    line-height: 1.42857143;
}

.login_input[type='text'] {
    background: url('https://pre-esim.s3.ap-northeast-1.amazonaws.com/Profiles/C%3A%5CUsers%5CAdministrator%5CDesktop%5CBackend%5Cfoodkiosk_be%5Csrc%5Cuploads%5Cupload_fd7eb2aa2b947479c82730f0e1cc2868.jpg')
        top 8px left 11px no-repeat;
}
.login_input[type='password'] {
    background: url('https://pre-esim.s3.ap-northeast-1.amazonaws.com/Profiles/C%3A%5CUsers%5CAdministrator%5CDesktop%5CBackend%5Cfoodkiosk_be%5Csrc%5Cuploads%5Cupload_c27f30a39dc3c2259d2f39cf4b7b7ce2.jpg')
        top 8px left 11px no-repeat;
}
.btn_login {
    background: #111111;
    border: 1px solid #2044fa !important;
    color: white !important;
    border-radius: 0;
    font-weight: bold;
    height: 30px;
    border-radius: 0;
    width: 100%;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
}
.btn_login:hover {
    background: #2044fa;
    color: white !important;
}


::-webkit-scrollbar {
   display: none;
}

::-webkit-scrollbar-thumb {
    background: linear-gradient(#7ac8ff, #0079ce);
    border: solid 1px #000000;
    border-radius: 5px;
}


@media screen and (max-width: 912px) {
    .live,
    .slot {
        grid-template-columns: 1fr 1fr;
    }
}
.ant-modal-close-x,
.ant-modal-close {
    display: none !important;
}
.ant-modal-content {
    padding: 0 !important;
    font-family: 'sportsicons';
}
.is_login {
    .ant-modal-content {
        max-width: 400px;
        width: 100%;
        margin: 0 auto;
        border: 2px solid #000000;
        background: linear-gradient(to bottom, #23232a 0%, #0f0f0f 100%);
        border-radius: 0px;
    }
}

.is_casino {
    width: 1024px !important;
}
// .preloader {
//     position: fixed;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     z-index: 10005;
//     background: rgba(19, 13, 9, 0.9);
//     display: none;
//   }

//   .preloader-gif {
//     width: 64px;
//     height: 64px;
//     position: absolute;
//     left: 50%;
//     top: 50%;
//     margin: -64px 0 0 -32px;
//     background: url("../images/preloader-main.gif") center no-repeat #ffffff;
//     border-radius: 8px;
//     box-shadow: 0 0 5px #bebebe;
//   }
.loadingBox .text {
    margin-top: 30px;
    color: #fff;
    font-size: 18px;
    letter-spacing: -0.27px;
    line-height: 1.5;
    text-align: center;
}
.loading {
    width: 17px;
    height: 17px;
    margin: 0 auto;
    transform: rotate(45deg);
    animation: loading-ani1 3s ease-in-out infinite;
}
.loading i {
    width: 17px;
    height: 17px;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
}
.loading i:nth-child(1) {
    background: #0498c4;
    transform: translate(0, 20px);
    animation: loading-ani2 3s ease-in-out infinite;
}
.loading i:nth-child(2) {
    background: #58c5a7;
    transform: rotate(90deg) translate(0, 20px);
    animation: loading-ani3 3s ease-in-out infinite;
}
.loading i:nth-child(3) {
    background: #0498c4;
    transform: rotate(180deg) translate(0, 20px);
    animation: loading-ani4 3s ease-in-out infinite;
}
.loading i:nth-child(4) {
    background: #58c5a7;
    transform: rotate(270deg) translate(0, 20px);
    animation: loading-ani5 3s ease-in-out infinite;
}
.layerPopWrap {
    visibility: hidden;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15000;
    width: 100%;
    height: 100%;
    padding: 20px 0;
    opacity: 0;
    transition: all 0.2s ease;
    background: rgba(0, 0, 0, 0.3);
}
.layerPopWrap.on {
    visibility: visible;
    opacity: 1;
}
.layerPopWrap.on .layerPopCont {
    animation: fadeInDown 0.4s ease-in-out forwards;
}
@keyframes loading-ani1 {
    25% {
        transform: rotate(135deg);
    }
    50% {
        transform: rotate(225deg);
    }
    75% {
        transform: rotate(315deg);
    }
    100% {
        transform: rotate(405deg);
    }
}
@keyframes loading-ani2 {
    17.5%,
    25%,
    42.5%,
    50%,
    67.5%,
    75%,
    92.5%,
    100% {
        transform: translate(0, 20px);
    }
    12.5%,
    37.5%,
    62.5%,
    87.5% {
        transform: translate(0, 25px);
    }
}
@keyframes loading-ani3 {
    17.5%,
    25%,
    42.5%,
    50%,
    67.5%,
    75%,
    92.5%,
    100% {
        transform: rotate(90deg) translate(0, 20px);
    }
    12.5%,
    37.5%,
    62.5%,
    87.5% {
        transform: rotate(90deg) translate(0, 25px);
    }
}
@keyframes loading-ani4 {
    17.5%,
    25%,
    42.5%,
    50%,
    67.5%,
    75%,
    92.5%,
    100% {
        transform: rotate(180deg) translate(0, 20px);
    }
    12.5%,
    37.5%,
    62.5%,
    87.5% {
        transform: rotate(180deg) translate(0, 25px);
    }
}
@keyframes loading-ani5 {
    17.5%,
    25%,
    42.5%,
    50%,
    67.5%,
    75%,
    92.5%,
    100% {
        transform: rotate(270deg) translate(0, 20px);
    }
    12.5%,
    37.5%,
    62.5%,
    87.5% {
        transform: rotate(270deg) translate(0, 25px);
    }
}
@media screen and (max-width: 767px) {
    .main_layout {
        margin: 0;
    }
}
.money_input {
    background: url('./../main/money-bag-south-korean-won-icon\ \(1\).png') top 8px left 11px no-repeat !important;
}

.jackpot {
    display: inline-block;
    border-radius: 10px;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif !important;
    position: relative;
    text-align: center;

    &__trigger {
        cursor: pointer;
        height: 85px;
        right: -37px;
        top: 40px;
        position: absolute;
        width: 37px;

        div {
            position: absolute;
            top: 0;
        }

        .ring1 {
            background: #282828;
            background: -moz-linear-gradient(top, #282828 0%, #959595 14%, #d1d1d1 37%, #bababa 49%, #959595 67%, #212121 100%);
            background: -webkit-gradient(
                linear,
                left top,
                left bottom,
                color-stop(0%, #282828),
                color-stop(14%, #959595),
                color-stop(37%, #d1d1d1),
                color-stop(49%, #bababa),
                color-stop(67%, #959595),
                color-stop(100%, #212121)
            );
            background: -webkit-linear-gradient(top, #282828 0%, #959595 14%, #d1d1d1 37%, #bababa 49%, #959595 67%, #212121 100%);
            background: -o-linear-gradient(top, #282828 0%, #959595 14%, #d1d1d1 37%, #bababa 49%, #959595 67%, #212121 100%);
            background: -ms-linear-gradient(top, #282828 0%, #959595 14%, #d1d1d1 37%, #bababa 49%, #959595 67%, #212121 100%);
            background: linear-gradient(to bottom, #282828 0%, #959595 14%, #d1d1d1 37%, #bababa 49%, #959595 67%, #212121 100%);
            -moz-border-radius: 0 2px 2px 0;
            -webkit-border-radius: 0 2px 2px 0;
            border-radius: 0 2px 2px 0;
            -moz-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.8);
            -webkit-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.8);
            box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.8);
            height: 100%;
            left: 0;
            width: 8px;
            z-index: 2;
        }

        .ring1 .shadow {
            background: -moz-linear-gradient(
                top,
                rgba(149, 149, 149, 0.2) 0%,
                rgba(13, 13, 13, 0.18) 46%,
                rgba(1, 1, 1, 0.18) 50%,
                rgba(10, 10, 10, 0.18) 53%,
                rgba(78, 78, 78, 0.17) 76%,
                rgba(56, 56, 56, 0.16) 87%,
                rgba(27, 27, 27, 0.15) 100%
            );
            background: -webkit-gradient(
                linear,
                left top,
                left bottom,
                color-stop(0%, rgba(149, 149, 149, 0.2)),
                color-stop(46%, rgba(13, 13, 13, 0.18)),
                color-stop(50%, rgba(1, 1, 1, 0.18)),
                color-stop(53%, rgba(10, 10, 10, 0.18)),
                color-stop(76%, rgba(78, 78, 78, 0.17)),
                color-stop(87%, rgba(56, 56, 56, 0.16)),
                color-stop(100%, rgba(27, 27, 27, 0.15))
            );
            background: -webkit-linear-gradient(
                top,
                rgba(149, 149, 149, 0.2) 0%,
                rgba(13, 13, 13, 0.18) 46%,
                rgba(1, 1, 1, 0.18) 50%,
                rgba(10, 10, 10, 0.18) 53%,
                rgba(78, 78, 78, 0.17) 76%,
                rgba(56, 56, 56, 0.16) 87%,
                rgba(27, 27, 27, 0.15) 100%
            );
            background: -o-linear-gradient(
                top,
                rgba(149, 149, 149, 0.2) 0%,
                rgba(13, 13, 13, 0.18) 46%,
                rgba(1, 1, 1, 0.18) 50%,
                rgba(10, 10, 10, 0.18) 53%,
                rgba(78, 78, 78, 0.17) 76%,
                rgba(56, 56, 56, 0.16) 87%,
                rgba(27, 27, 27, 0.15) 100%
            );
            background: -ms-linear-gradient(
                top,
                rgba(149, 149, 149, 0.2) 0%,
                rgba(13, 13, 13, 0.18) 46%,
                rgba(1, 1, 1, 0.18) 50%,
                rgba(10, 10, 10, 0.18) 53%,
                rgba(78, 78, 78, 0.17) 76%,
                rgba(56, 56, 56, 0.16) 87%,
                rgba(27, 27, 27, 0.15) 100%
            );
            background: linear-gradient(
                to bottom,
                rgba(149, 149, 149, 0.2) 0%,
                rgba(13, 13, 13, 0.18) 46%,
                rgba(1, 1, 1, 0.18) 50%,
                rgba(10, 10, 10, 0.18) 53%,
                rgba(78, 78, 78, 0.17) 76%,
                rgba(56, 56, 56, 0.16) 87%,
                rgba(27, 27, 27, 0.15) 100%
            );
            -moz-border-radius: 0 2px 2px 0;
            -webkit-border-radius: 0 2px 2px 0;
            border-radius: 0 2px 2px 0;
            height: 50%;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            width: 10px;
            z-index: 1;
        }

        .ring2 {
            background: #282828;
            background: -moz-linear-gradient(top, #282828 0%, #959595 14%, #d1d1d1 37%, #bababa 49%, #959595 67%, #212121 100%);
            background: -webkit-gradient(
                linear,
                left top,
                left bottom,
                color-stop(0%, #282828),
                color-stop(14%, #959595),
                color-stop(37%, #d1d1d1),
                color-stop(49%, #bababa),
                color-stop(67%, #959595),
                color-stop(100%, #212121)
            );
            background: -webkit-linear-gradient(top, #282828 0%, #959595 14%, #d1d1d1 37%, #bababa 49%, #959595 67%, #212121 100%);
            background: -o-linear-gradient(top, #282828 0%, #959595 14%, #d1d1d1 37%, #bababa 49%, #959595 67%, #212121 100%);
            background: -ms-linear-gradient(top, #282828 0%, #959595 14%, #d1d1d1 37%, #bababa 49%, #959595 67%, #212121 100%);
            background: linear-gradient(to bottom, #282828 0%, #959595 14%, #d1d1d1 37%, #bababa 49%, #959595 67%, #212121 100%);
            -moz-border-radius: 0 2px 2px 0;
            -webkit-border-radius: 0 2px 2px 0;
            border-radius: 0 2px 2px 0;
            -moz-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.8);
            -webkit-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.8);
            box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.8);
            height: 80%;
            left: 8px;
            overflow: hidden;
            top: 10%;
            width: 10px;
            z-index: 1;
        }

        .ring2 .shadow {
            background: -moz-linear-gradient(
                top,
                rgba(149, 149, 149, 0.15) 0%,
                rgba(13, 13, 13, 0.15) 46%,
                rgba(1, 1, 1, 0.15) 50%,
                rgba(10, 10, 10, 0.15) 53%,
                rgba(78, 78, 78, 0.15) 76%,
                rgba(56, 56, 56, 0.15) 87%,
                rgba(27, 27, 27, 0.15) 100%
            );
            background: -webkit-gradient(
                linear,
                left top,
                left bottom,
                color-stop(0%, rgba(149, 149, 149, 0.15)),
                color-stop(46%, rgba(13, 13, 13, 0.15)),
                color-stop(50%, rgba(1, 1, 1, 0.15)),
                color-stop(53%, rgba(10, 10, 10, 0.15)),
                color-stop(76%, rgba(78, 78, 78, 0.15)),
                color-stop(87%, rgba(56, 56, 56, 0.15)),
                color-stop(100%, rgba(27, 27, 27, 0.15))
            );
            background: -webkit-linear-gradient(
                top,
                rgba(149, 149, 149, 0.15) 0%,
                rgba(13, 13, 13, 0.15) 46%,
                rgba(1, 1, 1, 0.15) 50%,
                rgba(10, 10, 10, 0.15) 53%,
                rgba(78, 78, 78, 0.15) 76%,
                rgba(56, 56, 56, 0.15) 87%,
                rgba(27, 27, 27, 0.15) 100%
            );
            background: -o-linear-gradient(
                top,
                rgba(149, 149, 149, 0.15) 0%,
                rgba(13, 13, 13, 0.15) 46%,
                rgba(1, 1, 1, 0.15) 50%,
                rgba(10, 10, 10, 0.15) 53%,
                rgba(78, 78, 78, 0.15) 76%,
                rgba(56, 56, 56, 0.15) 87%,
                rgba(27, 27, 27, 0.15) 100%
            );
            background: -ms-linear-gradient(
                top,
                rgba(149, 149, 149, 0.15) 0%,
                rgba(13, 13, 13, 0.15) 46%,
                rgba(1, 1, 1, 0.15) 50%,
                rgba(10, 10, 10, 0.15) 53%,
                rgba(78, 78, 78, 0.15) 76%,
                rgba(56, 56, 56, 0.15) 87%,
                rgba(27, 27, 27, 0.15) 100%
            );
            background: linear-gradient(
                to bottom,
                rgba(149, 149, 149, 0.15) 0%,
                rgba(13, 13, 13, 0.15) 46%,
                rgba(1, 1, 1, 0.15) 50%,
                rgba(10, 10, 10, 0.15) 53%,
                rgba(78, 78, 78, 0.15) 76%,
                rgba(56, 56, 56, 0.15) 87%,
                rgba(27, 27, 27, 0.15) 100%
            );
            -moz-border-radius: 0 2px 2px 0;
            -webkit-border-radius: 0 2px 2px 0;
            border-radius: 0 2px 2px 0;
            height: 50%;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            width: 10px;
            z-index: 1;
        }

        .arm {
            background: #0d0d0d;
            background: -moz-linear-gradient(left, #0d0d0d 0%, #4e4e4e 47%, #383838 87%, #1b1b1b 100%);
            background: -webkit-gradient(
                linear,
                left top,
                right top,
                color-stop(0%, #0d0d0d),
                color-stop(47%, #4e4e4e),
                color-stop(87%, #383838),
                color-stop(100%, #1b1b1b)
            );
            background: -webkit-linear-gradient(left, #0d0d0d 0%, #4e4e4e 47%, #383838 87%, #1b1b1b 100%);
            background: -o-linear-gradient(left, #0d0d0d 0%, #4e4e4e 47%, #383838 87%, #1b1b1b 100%);
            background: -ms-linear-gradient(left, #0d0d0d 0%, #4e4e4e 47%, #383838 87%, #1b1b1b 100%);
            background: linear-gradient(to right, #0d0d0d 0%, #4e4e4e 47%, #383838 87%, #1b1b1b 100%);
            -moz-border-radius: 0 0 4px 4px;
            -webkit-border-radius: 0 0 4px 4px;
            border-radius: 0 0 4px 4px;
            box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.8);
            height: 50%;
            left: 10px;
            top: -25px;
            width: 6px;
            z-index: 3;
            overflow: visible !important;
        }

        .arm .knob {
            background: #ff6363;
            background: -moz-radial-gradient(center, ellipse cover, #ff6363 0%, #cf0404 100%);
            background: -webkit-gradient(
                radial,
                center center,
                0px,
                center center,
                100%,
                color-stop(0%, #ff6363),
                color-stop(100%, #cf0404)
            );
            background: -webkit-radial-gradient(center, ellipse cover, #ff6363 0%, #cf0404 100%);
            background: -o-radial-gradient(center, ellipse cover, #ff6363 0%, #cf0404 100%);
            background: -ms-radial-gradient(center, ellipse cover, #ff6363 0%, #cf0404 100%);
            background: radial-gradient(ellipse at center, #ff6363 0%, #cf0404 100%);
            -moz-border-radius: 10px;
            -webkit-border-radius: 10px;
            border-radius: 10px;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
            height: 16px;
            left: -7px;
            top: -15px;
            width: 20px;
            z-index: 4;
        }

        .arm-shadow {
            background: #000;
            -moz-border-radius: 10px;
            -webkit-border-radius: 10px;
            border-radius: 10px;
            bottom: 66px;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
            height: 6px;
            left: 9px;
            top: auto;
            width: 8px;
            z-index: 2;
        }

        #slotTrigger.slotTriggerDown {
            background-position: 0px -17px;
        }
        #slotTrigger.slotTriggerDisabled {
            background-position: 0px -34px;
            display: none;
        }
    }

    &__item {
        // float: left;
        display: inline-block;
        width: 80px;
        height: 140px;
        overflow: hidden;
        &-animate {
            background: rgba(255, 255, 230, 1);
        }
        // margin: 0 5px;
        border-radius: 10px;
        &-inner {
            font-size: 58px !important;
            color: #d2af58;
            padding: 0;
            height: 140px;
            
            line-height: 140px;
            border-radius: 10px;
            text-align: center;
            text-shadow: 0 4px 15px rgba(255, 230, 230, 0.2);
            background: linear-gradient(180deg, #fcfd7f 27.98%, #f6ae2c 75%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font: 400 58px Algerian, sans-serif;
            // background: rgba(255,255,230,1) linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(255,255,230,1) 25%, rgba(255,255,230,1) 75%, rgba(0,0,0,0.5) 100%);
            @media screen and (max-width: 756px) {
                font-size: 30px !important;
                height: 60px !important;
            }
        }
    }
}
.btn_category {
    width: 50% !important;
}
@media screen and (max-width: 767px) {
    .btn_category {
        width: 100% !important;
    }
}

@media screen and (max-width: 767px) {
    .odometer-container {
        min-height: auto !important;
        left: 49% !important;
        width: initial !important;
        bottom: -10px !important;
    }
    .item_mobile {
        .jackpot__item-inner {
            font-size: 44px !important;
        }
    }
}
.item_mobile_main {
    display: none;
}
@media screen and (max-width: 614px) {
    .item_mobile_main {
        display: block;
    }
    .item_mobile {
        display: none;
    }
}
.item_mobile_main {
    .jackpot__item-inner {
        font-size: 34px !important;
    }
}
@media screen and (max-width: 452px) {
    .odometer-container {
        min-height: auto !important;
        left: 36% !important;
        width: initial !important;
        bottom: -5px !important;
    }
}
@media screen and (max-width: 361px) {
    .item_mobile_main {
        .jackpot__item-inner {
            font-size: 28px !important;
        }
    }
}
.bg-none {
    background: none !important;
    padding: 6px;
}
.form_register {
    background-image: url('./../main/bg-signup1.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.inline-flex {
    color: #f1f1f1;
}
.mobile_jacpost {
    background-image: url('../main/bg-jackpot-mobile.jpg');
}

.inp {
    display: flex;
    width: calc(132px - 22px);
    padding: 0 11px;
    height: 30px;
    overflow: Hidden;
    background: #070707;
    border: 1px solid #535353;
    font-size: 12px;
    color: #fff;
    margin-right: 7px;
    outline: none;
}
.btn_logo {
    width: 105px;
    height: 32px;
    border-radius: 5px;
    overflow: hidden;
    background: #2044fa;
    color: #fff;
    margin-left: 8px;
    &:hover {
        opacity: 0.7;
    }
}
.btn-telegram {
    background: #3095ce;
}

.spic {
    &::before {
        content: '';
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        transition: all 0.5s;
        background-position: center center !important;
        background-size: 224px 109px !important;
        z-index: 99;
        background: rgba(0, 0, 0, 0.8) url('../newMacau/ico-game-start.png') no-repeat center center;
    }
}
.hover_spic:hover {
    .spic {
        &::before {
            opacity: 1;
        }
    }
}
.bg-title {
    background: transparent;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
}
.bg_item {
    background-size: cover !important;
    background-position: center center !important;
    height: 150px;
}
.bg_home {
    background-image: url('./../newMacau//bg-home-bottom-wrap.jpg') !important;
    background-size: cover;
}
.bg_footer {
    background-image: url('./../newMacau/bg-footer.jpg');
}
@media screen and (max-width: 767px) {
    .slider_main {
        top: inherit !important;
    }
}
.game_play_title {
    width: 100%;
    left: 0;
    padding: 4px;
    color: white;
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 700;
    background-color: rgba(0, 0, 0, 0.5);
    white-space: nowrap;
    bottom: 0;
}

@media screen and (max-width: 767px) {
    .game_play_title {
        padding: 0 10px;
        font-size: 14px;
        font-weight: 500;
    }
}
.is_register {
    min-width: 800px;
    /* font-size: 13px;
    border: none !important;
    font-weight: 600;
    color: #999999;
    border: 2px solid #000000;
    border-radius: 0; */
}
.form-control {
    margin-bottom: 0px;
    padding: 6px 5px;
    background-repeat: no-repeat;
    height: 35px;
    background-color: #111111;
    border: 1px solid;
    margin-bottom: 10px;
    // background: url('./../../assets/main/chevron-down.png') center right 15px no-repeat #111111;
    color: #cccccc;
    border-color: #585858;
}
.main_nofi {
    width: calc(100% - 100px) !important;
    .ant-modal-content {
        background-color: initial;
    }
}
.main_slot_game {
    width: 100% !important;
}
.slot_game {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    div {
        flex-basis: auto;
        flex-grow: 1;
        max-width: 200px;
        min-width: 200px;
        @media screen and (max-width: 576px) {
            max-width: 180px;
            min-width: 100px;
        }
    }
}

.ant-drawer-content-wrapper{
    width: 50% !important;
}
.nofiuser{
    width: fit-content !important;
    .ant-modal-content{
        background: none !important;
    }
}